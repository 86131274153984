<template>
  <v-dialog persistent v-model="dialog" :max-width="options.width" :style="{ zIndex: 20000 }" @keydown.esc="cancel">
    <v-card :elevation="2" v-if="!loading">
      {{ pb }}
      <v-card-text class="py-3">
        <template v-if="pb.ubBodaRealAux == 'PANTICOSA'">
          <template v-if="page == 1">
            <v-form ref="form1">
              <h1 class="primary--text mb-5">PAREJA</h1>
              <v-row>
                <v-col cols="12" md="6">
                  <h2 class="primary--text">DATOS</h2>
                  <div class="ms-5">
                    <v-text-field class="mb-4 mt-3" readonly dense label="NOMBRE PAREJA" hide-details="" :value="datos.datos.novios ? (datos.datos.novios.startsWith('PB ') ? datos.datos.novios.slice(3) : datos.datos.novios) : ''"></v-text-field>
                    <v-text-field class="mb-4 mt-3" readonly dense label="FECHA ENLACE" hide-details="" :value="datos.datos.fechaBodaReal"></v-text-field>
                  </div>
                  <h2 class="primary--text">ESPACIOS</h2>
                  <div class="ms-5">
                    <v-text-field class="mb-4 ms-5 mt-3" dense readonly label="LUGAR" hide-details="" :value="datos.datos.ubBodaReal"></v-text-field>
                    <v-text-field class="mb-4 ms-5 mt-3" dense label="APERITIVO" hide-details="" v-model="pb.aptvo"></v-text-field>
                    <v-text-field class="mb-4 ms-5 mt-3" dense label="BANQUETE" hide-details="" v-model="pb.banquete"></v-text-field>
                  </div>
                </v-col>
                <v-col cols="12" md="6">
                  <h2 class="primary--text">PRUEBA DE BODA</h2>
                  <div class="ms-5">
                    <v-text-field class="mb-4 mt-3" dense label="FECHA DEGUSTACIÓN" hide-details="" v-model="pb.fechaDegustacion"></v-text-field>
                    <v-text-field class="mb-4 mt-3 noArrow" :rules="[rules.req]" type="number" dense label="COMENSALES DÍA DEGUSTACIÓN*" hide-details="" v-model="pb.adultos"></v-text-field>
                    <v-textarea filled dense label="INTOLERANCIAS DÍA DEGUSTACIÓN" hide-details="" v-model="pb.novios"></v-textarea>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-textarea filled dense label="OBSERVACIONES" hide-details="" v-model="pb.observacionesPareja"></v-textarea>
                </v-col>
              </v-row>
              <v-divider class="my-2"></v-divider>
              <h1 class="primary--text mb-5">BODA</h1>
              <v-row>
                <v-col cols="12" md="6">
                  <h2 class="primary--text">PERSONAS BODA</h2>
                  <div class="ms-5">
                    <v-text-field class="mb-4 mt-3 noArrow" dense :rules="[rules.req]" type="number" label="ADULTOS*" hide-details="auto" v-model="pb.adultosBoda"></v-text-field>
                    <v-text-field class="mb-4 mt-3 noArrow" dense :rules="[rules.req]" type="number" label="NIÑOS*" hide-details="auto" v-model="pb.ninosBoda"></v-text-field>
                  </div>
                </v-col>
                <v-col cols="12" md="6">
                  <h2 class="primary--text">HORARIO</h2>
                  <div class="ms-5">
                    <v-text-field class="mb-4 mt-3" dense label="ENLACE" hide-details="" v-model="pb.hEnlace"></v-text-field>
                    <v-text-field class="mb-4 mt-3" dense label="APERITIVO" hide-details="" v-model="pb.hAptvo"></v-text-field>
                    <v-text-field class="mb-4 mt-3" dense label="COMIDA/CENA" hide-details="" v-model="pb.hComida"></v-text-field>
                    <v-text-field class="mb-4 mt-3" dense label="FIN EVENTO" hide-details="" v-model="pb.hFinEvento"></v-text-field>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-textarea filled dense label="OBSERVACIONES" hide-details="" v-model="pb.observacionesBoda"></v-textarea>
                </v-col>
              </v-row>
              <v-divider class="my-2"></v-divider>
              <v-row>
                <v-col cols="12">
                  <h2 class="primary--text">ENLACE & NECESIDADES</h2>
                  <v-radio-group
                    hide-details=""
                    v-model="pb.enlace"
                    column
                  >
                    <div class="ms-5">
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-radio hide-details="" :value="1" label="ENLACE RELIGIOSO"></v-radio>
                          <div v-if="pb.enlace == 1">
                            <v-text-field class="mb-4 mt-3" dense label="IGLESIA/POBLACIÓN" hide-details="" v-model="pb.iglesia"></v-text-field>
                          </div>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-radio hide-details="" :value="2" label="ENLACE CIVIL"></v-radio>
                          <v-text-field class="mb-4 mt-3" v-if="pb.enlace == 2" dense label="UBICACIÓN" hide-details="" v-model="pb.eUbicacion"></v-text-field>
                        </v-col>
                      </v-row>
                    </div>
                  </v-radio-group>
                  <h2 class="primary--text mt-3">GETIÓN MASFARRÉ</h2>
                  <div class="ms-5 d-flex">
                    <v-checkbox class="me-7" hide-details="" label="SILLAS" v-model="pb.mfSillas"></v-checkbox>
                    <v-checkbox class="me-7" hide-details="" label="MEGAFONÍA" v-model="pb.mfMegafonia"></v-checkbox>
                    <v-checkbox class="me-7" hide-details="" label="OTROS" v-model="pb.mfOtras"></v-checkbox>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-textarea filled dense label="OBSERVACIONES" hide-details="" v-model="pb.observacionesEnlace"></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </template>
          <template v-if="page == 2">
            <v-form ref="form2">
              <h1 class="primary--text mb-5">APERITIVO</h1>
              <v-row>
                <v-col cols="12" md="6">
                  <h2 class="primary--text">RECEPCIÓN</h2>
                  <v-checkbox class="mt-0" hide-details="" label="Cava de bienvenida y Limonada" v-model="pb.cavaYLimonada"></v-checkbox>
                </v-col>
                <v-col cols="12" md="6">
                  <h2 class="primary--text">ESPACIO BIENVENIDA</h2>
                  <v-text-field class="mb-4 mt-3" dense label="ESPACIO BIENVENIDA" hide-details="" v-model="pb.espacioBienvenida"></v-text-field>
                </v-col>
                <v-divider class="mb-3"></v-divider>
                <v-col cols="12" md="4">
                  <span class="d-flex">
                    <h2 class="primary--text">BUFFETS BIENVENIDA</h2>
                    <h6 class="secondary--text ms-2">(ELEGIR 1 O 2)</h6>
                  </span>
                  <v-checkbox class="mt-0" hide-details="" label="Jamoncito del bueno" v-model="pb.jamoncito"></v-checkbox>
                  <div class="ms-8">
                    <h4 class="secondary--text">Jamón Ibérico de Guijuelo al corte</h4>
                    <h4 class="secondary--text">Pan cristal & tomate rosa</h4>
                  </div>
                  <v-checkbox class="mt-0" hide-details="" label="La charcutería" v-model="pb.laCharcuteria"></v-checkbox>
                  <div class="ms-8">
                    <h4 class="secondary--text">Chorizo picante de León- Secallona de Vic</h4>
                    <h4 class="secondary--text">Lomo ibérico - Cabeza de jabalí - Bull negro</h4>
                    <h4 class="secondary--text">Pan de payes y AOVE del Somontano</h4>
                  </div>
                  <v-checkbox class="mt-0" hide-details="" label="La quesería" v-model="pb.laQueseria"></v-checkbox>
                  <div class="ms-8">
                    <h4 class="secondary--text">Degustación de quesos de la zona</h4>
                    <h4 class="secondary--text">Mermeladas & frutos silvestres</h4>
                    <h4 class="secondary--text">Miller's toast - grissinis - picos campero</h4>
                  </div>
                  <v-checkbox class="mt-0" hide-details="" label="Japonés" v-model="pb.japones"></v-checkbox>
                  <div class="ms-8">
                    <h4 class="secondary--text">SushiRolls - hosomakis - nigiris - sashimi moriawase</h4>
                    <h4 class="secondary--text">Ensalada de algas y sésamo</h4>
                    <h4 class="secondary--text">Soja - wasabi - jengib</h4>
                  </div>
                  <v-checkbox class="mt-0" hide-details="" label="Pato" v-model="pb.pato"></v-checkbox>
                  <div class="ms-8">
                    <h4 class="secondary--text">Mil hojas de foie, membrillo, queso y manzana</h4>
                    <h4 class="secondary--text">Paté de campaña a las finas hierbas</h4>
                    <h4 class="secondary--text">Confitura de violetas - pan Guitatu - uvas</h4>
                  </div>
                  <v-checkbox class="mt-0" hide-details="" label="Vermutería" v-model="pb.vermuteria"></v-checkbox>
                  <div class="ms-8">
                    <h4 class="secondary--text">Olivas - Chips - Snacks</h4>
                    <h4 class="secondary--text">Encurtidos & Laterío "Barrecha"</h4>
                    <h4 class="secondary--text">Vermut casero macerado con naranja</h4>
                  </div>
                </v-col>
                <v-col cols="12" md="4">
                  <span class="d-flex">
                    <h2 class="primary--text">BUFFETS TEMÁTICOS</h2>
                    <h6 class="secondary--text ms-2">(ELEGIR 1 O 2)</h6>
                  </span>
                  <v-checkbox class="mt-0" hide-details="" label="Arroces del mundo" v-model="pb.arroces"></v-checkbox>
                  <div class="ms-8">
                    <h4 class="secondary--text">Fideuá de chipirones en dos texturas</h4>
                    <h4 class="secondary--text">Risotto de arroz carnaroli con ceps y trompetillas</h4>
                  </div>
                  <v-checkbox class="mt-0" hide-details="" label="Espetos" v-model="pb.espetos"></v-checkbox>
                  <div class="ms-8">
                    <h4 class="secondary--text">Pulpo y panceta a la parrilla</h4>
                    <h4 class="secondary--text">Chipirones frescos con su picada</h4>
                  </div>
                  <v-checkbox class="mt-0" hide-details="" label="Take away" v-model="pb.takeAway"></v-checkbox>
                  <div class="ms-8">
                    <h4 class="secondary--text">Gigot de ternera - tupinambo - patatas paja</h4>
                    <h4 class="secondary--text">Gulas a la Bilbana y crackers</h4>
                  </div>
                  <v-checkbox class="mt-0" hide-details="" label="Km.0" v-model="pb.km0"></v-checkbox>
                  <div class="ms-8">
                    <h4 class="secondary--text">Huevos estrellados</h4>
                    <h4 class="secondary--text">Migas de pastor</h4>
                  </div>
                  <v-checkbox class="mt-0" hide-details="" label="Tacos bar" v-model="pb.tacosBar"></v-checkbox>
                  <div class="ms-8">
                    <h4 class="secondary--text">Pulled pork - barbacoa - jalapeños</h4>
                    <h4 class="secondary--text">Mini kebab de pollo desmechado</h4>
                  </div>
                  <v-checkbox class="mt-0" hide-details="" label="Cocina en directo" v-model="pb.cocinaEnDirecto"></v-checkbox>
                  <div class="ms-8">
                    <h4 class="secondary--text">Magret de pato - reducción PC - confitura cereza</h4>
                    <h4 class="secondary--text">Gamba de Palamós a la plancha</h4>
                  </div>
                </v-col>
                <v-col cols="12" md="4">
                  <span class="d-flex">
                    <h2 class="primary--text">BUFFETS ESPECTÁCULO</h2>
                    <h6 class="error--text ms-2">(NO INCLUIDO)</h6>
                  </span>
                  <v-checkbox class="mt-0" hide-details="" label="Pulpeiro" v-model="pb.pulpeiro"></v-checkbox>
                  <div class="ms-8">
                    <h4 class="secondary--text">Pulpo a la gallega</h4>
                    <h4 class="secondary--text">Patata y alioli de pimentón</h4>
                  </div>
                  <v-checkbox class="mt-0" hide-details="" label="Marinero" v-model="pb.marinero"></v-checkbox>
                  <div class="ms-8">
                    <h4 class="secondary--text">Gambas a la sal</h4>
                    <h4 class="secondary--text">Canaillas</h4>
                    <h4 class="secondary--text">Mejillones a la americana</h4>
                    <h4 class="secondary--text">Langostinos</h4>
                    <h4 class="secondary--text">Berberechos</h4>
                  </div>
                  <v-checkbox class="mt-0" hide-details="" label="Ahumados" v-model="pb.ahumados"></v-checkbox>
                  <div class="ms-8">
                    <h4 class="secondary--text">salmón noruego</h4>
                    <h4 class="secondary--text">Bacalao Islandia</h4>
                    <h4 class="secondary--text">Salsa tártara y confitura de pimiento asado</h4>
                  </div>
                  <v-checkbox class="mt-0" hide-details="" label="Puro show" v-model="pb.puroShow"></v-checkbox>
                  <div class="ms-8">
                    <h4 class="secondary--text">Chuletón Vaca madurada 40 días Summatura</h4>
                    <h4 class="secondary--text">Patata asada, pimientos de Lodosa y mostaza</h4>
                  </div>
                  <!-- <v-divider class="mb-3"></v-divider>
                  <h2 class="primary--text">OTRAS DELICATESSEN</h2>
                  <h4 class="primery--text">(ELEGIR 6)</h4>
                  
  
                  <v-divider class="my-2"></v-divider>
                  <h2 class="primary--text">BAR</h2>
                  <v-checkbox class="mt-0" hide-details="" label="Aguas, Refrescos, Cervezas, Vermut" v-model="pb.bar"></v-checkbox>
                  <v-divider class="my-2"></v-divider>
                  <h2 class="primary--text">BODEGA</h2>
                  <v-checkbox class="mt-0" hide-details="" label="Vinos DO Somontano (blanco, rosado y tinto)" v-model="pb.bar"></v-checkbox>
                  <v-divider class="my-2"></v-divider>
                  <h2 class="primary--text">CERVECEANDO (no incluido)</h2>
                  <v-checkbox class="mt-0" hide-details="" label="Selección de cervezas nacionales o intenacionales" v-model="pb.bar"></v-checkbox>
                  <v-divider class="my-2"></v-divider>
                  <h2 class="primary--text">OBSERVACIONES APERITIVO:</h2>
                  <v-textarea filled dense hide-details="" v-model="pb.obsAptvo"></v-textarea> -->
                </v-col>
                <v-col cols="12">
                  <v-textarea filled dense label="OBSERVACIONES" hide-details="" v-model="pb.observacionesBuffet"></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </template>
          <template v-if="page == 3">
            <v-form ref="form3">
              <h1 class="primary--text">?</h1>
              <v-row>
                <v-col cols="12" class="pb-0">
                  <span class="d-flex">
                    <h2 class="primary--text">DELICATESSEN</h2>
                    <h6 class="secondary--text ms-2">(ELEGIR 6)</h6>
                  </span>
                </v-col>
                <v-col cols="12" md="6" class="pt-0">
                  <v-checkbox class="mt-0" hide-details="" label="Air baguette de jamón ibérico de Guijuelo" v-model="pb.air"></v-checkbox>
                  <v-checkbox class="mt-0" hide-details="" label="Ssäm de pollo crispy con lima y chile" v-model="pb.ssam"></v-checkbox>
                  <v-checkbox class="mt-0" hide-details="" label="Mollete de bonito pibil" v-model="pb.mollete"></v-checkbox>
                  <v-checkbox class="mt-0" hide-details="" label="Croquetas de pollo a la chilindrón" v-model="pb.croquetas"></v-checkbox>
                  <v-checkbox class="mt-0" hide-details="" label="Bikini de berenjena trufada" v-model="pb.bikini"></v-checkbox>
                  <v-checkbox class="mt-0" hide-details="" label="Mas brava mas" v-model="pb.bravas"></v-checkbox>
                  <v-checkbox class="mt-0" hide-details="" label="Tataki de atún, vinagreta de soja y ensalada wakame" v-model="pb.tataki"></v-checkbox>
                  <v-checkbox class="mt-0" hide-details="" label="Torreznos con alioli de lima" v-model="pb.torreznos"></v-checkbox>
                  <v-checkbox class="mt-0" hide-details="" label="Bao de confit de pato salsa hoisin y cebolla roja" v-model="pb.bao"></v-checkbox>
                  <v-checkbox class="mt-0" hide-details="" label="Huevo poché - sobrasada - trufa de verano" v-model="pb.huevoPache"></v-checkbox>
                </v-col>
                <v-col cols="12" md="6" class="pt-0">
                  <v-checkbox class="mt-0" hide-details="" label="Mini croissant de costilla ahumada" v-model="pb.mini"></v-checkbox>
                  <v-checkbox class="mt-0" hide-details="" label="Crujiente de manitas de cerdo y tartar de gamba roja" v-model="pb.crujiente"></v-checkbox>
                  <v-checkbox class="mt-0" hide-details="" label="Zamburiñas gallegas gratinadas" v-model="pb.zamburinas"></v-checkbox>
                  <v-checkbox class="mt-0" hide-details="" label="Brioche de rabo de buey con cebolla frita" v-model="pb.brioche"></v-checkbox>
                  <v-checkbox class="mt-0" hide-details="" label="Arroz meloso de pato, rosignol, reducción de PX" v-model="pb.arroz"></v-checkbox>
                  <v-checkbox class="mt-0" hide-details="" label="Tartar de atún con wanton y guacamole" v-model="pb.tartar"></v-checkbox>
                  <v-checkbox class="mt-0" hide-details="" label="Coca de roastbeef, germinados & tártara" v-model="pb.coca"></v-checkbox>
                  <v-checkbox class="mt-0" hide-details="" label="Anchoa - concassé de tomate rosa - pan fino" v-model="pb.anchoa"></v-checkbox>
                  <v-checkbox class="mt-0" hide-details="" label="Albóndigas de ciervo glaseadas" v-model="pb.albondigas"></v-checkbox>
                  <v-checkbox class="mt-0" hide-details="" label="Borraja en tempura y miel" v-model="pb.borraja"></v-checkbox>
                </v-col>
              </v-row>
              <v-divider class="my-2"></v-divider>
              <h1 class="primary--text">BAR & BODEGA</h1>
              <v-row>
                <v-col cols="12" md="4">
                  <span class="d-flex">
                    <h2 class="primary--text">BAR</h2>
                  </span>
                  <v-checkbox class="mt-0" hide-details="" label="Cervezas" v-model="pb.cervezas"></v-checkbox>
                  <v-checkbox class="mt-0" hide-details="" label="Vermut" v-model="pb.vermut"></v-checkbox>
                  <v-checkbox class="mt-0" hide-details="" label="Limonada casera" v-model="pb.limonadaCasera"></v-checkbox>
                  <v-checkbox class="mt-0" hide-details="" label="Refrescos" v-model="pb.refrescos"></v-checkbox>
                  <v-checkbox class="mt-0" hide-details="" label="Aguas" v-model="pb.aguas"></v-checkbox>
                </v-col>
                <v-col cols="12" md="4">
                  <span class="d-flex">
                    <h2 class="primary--text">BODEGA</h2>
                  </span>
                  <v-checkbox class="mt-0" hide-details="" label="Vinos Bodega DO Somontano" v-model="pb.vinos"></v-checkbox>
                </v-col>
                <v-col cols="12" md="4">
                  <span class="d-flex">
                    <h2 class="primary--text">OKTOBERFEST</h2>
                    <h6 class="secondary--text ms-2">(NO INCLUIDO)</h6>
                  </span>
                  <v-checkbox class="mt-0" hide-details="" label="Selección de cervezas nacionales o internacionales" v-model="pb.oktoberfest"></v-checkbox>
                </v-col>
              </v-row>
            </v-form>
          </template>
          <template v-if="page == 4">
            <h1 class="primary--text mb-5">MENÚ</h1>
            <v-row>
              <v-col cols="12" md="6">
                <h2 class="primary--text">PARA EMPEZAR (no incluido)</h2>
                <v-checkbox class="mt-0" hide-details="" label="Salmorejo de tomate rosa y cerezas, concassé de piña y langostino" v-model="pb.salmorejo"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Crema de carabineros, vieiras a la plancha y jamón ibérico" v-model="pb.cremaCarabineros"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Sopa de melón cantalupo, tartar de atún rojo y huevas de tobiko" v-model="pb.sopaMelon"></v-checkbox>
                <v-divider class="my-2"></v-divider>
                <h2 class="primary--text">PRIMER PLATO (elegir tres)</h2>
                <v-checkbox class="mt-0" hide-details="" label="Ensalada de pulpo y langostinos, escabeche de shitakes con vinagreta de mango-maracuyá" v-model="pb.ensaladaPulpo"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Cannelloni de pasta fresca con presa ibérica, trompeta negra, rosignol y rosignol" v-model="pb.canneloni"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Ravioli de txangurro y carabineros con emulsión de gamba roja" v-model="pb.ravioli"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Canelón de rape y langostinos con royal de cigalas" v-model="pb.canelonDeRape"></v-checkbox>
  
                <v-checkbox hide-details="" label="Pulpo braseado - cremoso de patata - alioli de pimentón - guacamole" v-model="pb.pulpoBraseado"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Merluza con marinera de sepia y pulpo y gratén de boletus" v-model="pb.merluza"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Bacalao 70º con tradicional salsa verde, langostino y crujiente de gambas y almendras" v-model="pb.bacalao"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Rodaballo con chipirones en su tinta y muselina de gamba roja" v-model="pb.menuRodaballo"></v-checkbox>
  
                <v-checkbox hide-details="" label="Ensalada de bogavante gallego y tartar de cítricos" v-model="pb.ensaladaBogavante"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Bogavante gallego asado a la parrilla" v-model="pb.bogavante"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Langosta imperial en dos texturas (media)" v-model="pb.langostaImperial"></v-checkbox>
                <v-divider class="my-2"></v-divider>
                <h2 class="primary--text">SORBETES (no incluido)</h2>
                <v-checkbox class="mt-0" hide-details="" label="Mandarina" v-model="pb.mandarina"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Mango&Gewürztraminer" v-model="pb.mangogewurztraminer"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Pink Prite" v-model="pb.pinkPrite"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Aperol Rosé" v-model="pb.aperol"></v-checkbox>
                <v-divider class="my-2"></v-divider>
              </v-col>
              <v-col cols="12" md="6">
                <h2 class="primary--text">SEGUNDO PLATO (elegir tres)</h2>
                <v-checkbox class="mt-0" hide-details="" label="Jarrete de lechazo al homo, con romero y patata rota" v-model="pb.jarreteDeLechazo"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Paletila de ternasco asado al modo tradicional con patatas panadera" v-model="pb.paletillaTernasco"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Ternasco glaseado a baja temperatura - patata trufada - boniato" v-model="pb.ternascoGlaseado"></v-checkbox>
                <v-checkbox hide-details="" label="Solomillo de ternera - cabernet Sauvignon - tupinambo - manzana" v-model="pb.solomilloTerneraReduccion"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Solomillo de ternera - escalopa de foile, cremoso de patata y oporto" v-model="pb.solomilloTerneraEscalopa"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Paletilla de cabrito con patatas panadera, romero, ajo y perejil" v-model="pb.paletillaDeCabrito"></v-checkbox>
                <v-divider class="my-2"></v-divider>
                <h2 class="primary--text">POSTRES (se degustarán todos)</h2>
                <v-checkbox readonly class="mt-0" hide-details="" :value="true" label="Torrija artesana con helado de vainilla de Madagascar"></v-checkbox>
                <v-checkbox readonly class="mt-0" hide-details="" :value="true" label="Lemon Pie & merengue y sopita de frambuesa"></v-checkbox>
                <v-checkbox readonly class="mt-0" hide-details="" :value="true" label="Bombón helado de chocolate & praliné"></v-checkbox>
                <v-checkbox readonly class="mt-0" hide-details="" :value="true" label="Galaxia de chocolate y sus satélites"></v-checkbox>
                <v-checkbox readonly class="mt-0" hide-details="" :value="true" label="Cremme Brullee con Ferrero Rocher"></v-checkbox>
                <v-checkbox readonly class="mt-0" hide-details="" :value="true" label="Lingote de contrastes y crema de moka"></v-checkbox>
                <v-checkbox readonly class="mt-0" hide-details="" :value="true" label="Tropic Passion y crema de chocolate con yogurt"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" v-model="pb.presentacionTarta" label="Presentación de tarta"></v-checkbox>
                <v-divider class="my-2"></v-divider>
                <h2 class="primary--text">BODEGA & CAVA</h2>
                <v-row>
                  <v-col cols="12" md="4">
                    <h4 class="secondary--text">BLANCOS</h4>
                    <v-checkbox class="mt-0" hide-details="" v-model="pb.lausChardonnay" label="Laus chardonnay"></v-checkbox>
                    <v-checkbox class="mt-0" hide-details="" v-model="pb.enate234Chardonnay" label="Enate 234 Chardonnay"></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="4">
                    <h4 class="secondary--text">TINTOS</h4>
                    <v-checkbox class="mt-0" hide-details="" v-model="pb.lausCrianza" label="Laus Crianza"></v-checkbox>
                    <v-checkbox class="mt-0" hide-details="" v-model="pb.enateCrianza" label="Enate Crianza"></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="4">
                    <h4 class="secondary--text">CAVAS</h4>
                    <v-checkbox class="mt-0" hide-details="" v-model="pb.cavaReyesAragon" label="Cava reyes de Argón Brut"></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-textarea filled dense label="OBSERVACIONES" hide-details="" v-model="pb.observacionesMenu"></v-textarea>
              </v-col>
            </v-row>
          </template>
          <template v-if="page == 5">
            <!-- <h1 class="primary--text mb-5">BODEGA</h1>
            <v-row>
              <v-col cols="12" md="4">
                <h2 class="primary--text">BLANCOS</h2>
                <v-checkbox class="mt-0" hide-details="" label="Viñas del Vero Macabeo-Chardonnay" v-model="pb.blancoVdvMacabeo"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Laus Chardonnay" v-model="pb.blancoLaus"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Pirineos 3404" v-model="pb.blancoPirineo"></v-checkbox>
                <v-checkbox hide-details="" label="Laura Lalanne" v-model="pb.blancoLaura"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Enate 234 Chardonnay" v-model="pb.blacoEnate"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Viñas del Vero Gewürztraminer" v-model="pb.blancoVdvGewurz"></v-checkbox>
              </v-col>
              <v-col cols="12" md="4">
                <h2 class="primary--text">TINTOS</h2>
                <v-checkbox class="mt-0" hide-details="" label="Viñas del Vero Crianza" v-model="pb.tintoVdv"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Laus Crianza" v-model="pb.tintoLaus"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="De Beroz Crianza" v-model="pb.tintoBeroz"></v-checkbox>
                <v-checkbox hide-details="" label="Lalanne Crianza" v-model="pb.tintoLalanne"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Enate Crianza" v-model="pb.tintoEnate"></v-checkbox>
              </v-col>
              <v-col cols="12" md="4">
                <h2 class="primary--text">CAVAS</h2>
                <v-checkbox class="mt-0" hide-details="" label="Cava Reyes de Aragón Brut" v-model="pb.cavaReyes"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Freixenet Vintage Reserva Brut" v-model="pb.cavaFreixenet"></v-checkbox>
                <v-checkbox hide-details="" label="Lucrecia Lalanne Brut" v-model="pb.cavaLucrecia"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Agustí Torelló&Mata Brut Nature" v-model="pb.cavaAgusti"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Cava Juvé&Camps reserva de Familia" v-model="pb.cavaJuve"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Otra bodega o Cava (detallar)" v-model="pb.otroCava"></v-checkbox>
                <v-textarea v-if="pb.otroCava" filled dense label="Otra Bodega" hide-details="" v-model="pb.otroCavaDetallado"></v-textarea>
              </v-col>
            </v-row> -->
            <h1 class="primary--text mb-5">OTROS MENÚS</h1>
            <v-row>
              <v-col cols="12">
                <h2 class="primary--text">MENÚ INFANTIL</h2>
                <v-checkbox class="mt-0" hide-details="" label="Macarrones con tomate casero" v-model="pb.infMacarrones"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Macarrones blancos" v-model="pb.infMacarronesBlancos"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Añadir canelones de carne gratinados" v-model="pb.infCanelones"></v-checkbox>
                <v-checkbox hide-details="" label="Finguers de pollo con patatas fritas" v-model="pb.infFingers"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Longaniza a la brasa con patatas fritas" v-model="pb.infLonganiza"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Hamburguesa al plato con patatas" v-model="pb.infHamburguesa"></v-checkbox>
                <v-checkbox hide-details="" label="Guardería" v-model="pb.guarderia"></v-checkbox>
                <v-textarea filled dense label="Intolerancias y observaciones" hide-details="" v-model="pb.infObs"></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <h2 class="primary--text my-5">MENÚS ESPECIALES (detallar)</h2>
                <v-textarea filled dense hide-details="" label="MENÚS ESPECIALES" v-model="pb.menuEspecial"></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea filled dense hide-details="" label="OBSERVACIONES" v-model="pb.observacionesOtrosMenus"></v-textarea>
              </v-col>
            </v-row>
          </template>
          <template v-if="page == 6">
            <h1 class="primary--text mb-5">FIN DE FIESTA</h1>
            <v-row>
              <v-col cols="12" md="6">
                <h2 class="primary--text">BARRA LIBRE</h2>
                <v-checkbox class="mt-0" hide-details="" label="Licores en mesa" v-model="pb.ffLicores"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Barra llibre" v-model="pb.ffBarra"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Otros licores - detallar:" v-model="pb.ffOtros"></v-checkbox>
                <v-textarea v-if="pb.ffOtros" filled dense label="" hide-details="" v-model="pb.otroFf"></v-textarea>
                <v-divider class="my-2"></v-divider>
                <h2 class="primary--text">RECENA</h2>
                <v-checkbox class="mt-0" hide-details="" label="Panini de bacon y queso" v-model="pb.paniniBaconQueso"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Coca de longaniza de Graus" v-model="pb.cocaLonganiza"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Tortilla de patata" v-model="pb.tortillaPatata"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Mini donuts & chocolate caliente" v-model="pb.miniDonuts"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Candy bar" v-model="pb.candyBar"></v-checkbox>
              </v-col>
            </v-row>
            <v-divider class="my-2"></v-divider>
            <h1 class="primary--text mb-5">BODA A MEDIDA</h1>
            <v-row>
              <v-col cols="12" md="6">
                <h2 class="primary--text">CARTELERÍA</h2>
                <h4 class="primary--text">(Tarjeta Aperitivo - Tarjeta Menú - Mesero - Seating)</h4>
                <div class="d-flex flex-row">
                  <v-checkbox class="mt-0" hide-details="" label="Modelo MasFarré (detallar modelo):" v-model="pb.carteleriaMf"></v-checkbox>
                  <div class="ms-5" v-if="pb.carteleriaMf">
                    <v-radio-group
                    class="mt-0"
                    hide-details=""
                    v-model="pb.modeloCarteleria"
                    column
                    >
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-radio class="my-0 mx-3" hide-details="" :value="1" label="03. AZUL"></v-radio>
                          <v-radio class="my-0 mx-3" hide-details="" :value="2" label="06. LOGO PERSONALIZADO"></v-radio>
                          <v-radio class="my-0 mx-3" hide-details="" :value="3" label="09. FLORES MORADAS"></v-radio>
                          <v-radio class="my-0 mx-3" hide-details="" :value="4" label="12. LAVANDA"></v-radio>
                          <v-radio class="my-0 mx-3" hide-details="" :value="5" label="15. VERDE AGUA"></v-radio>
                          <v-radio class="my-0 mx-3" hide-details="" :value="6" label="18. ELEGANTE"></v-radio>
                          <v-radio class="my-0 mx-3" hide-details="" :value="7" label="03. AZUL"></v-radio>
                          <v-radio class="my-0 mx-3" hide-details="" :value="8" label="03. AZUL"></v-radio>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-radio class="my-0 mx-3" hide-details="" :value="9" label="21. PRIMAVERA"></v-radio>
                          <v-radio class="my-0 mx-3" hide-details="" :value="10" label="24. FLORES AZULES"></v-radio>
                          <v-radio class="my-0 mx-3" hide-details="" :value="11" label="27. EUCALIPTO"></v-radio>
                          <v-radio class="my-0 mx-3" hide-details="" :value="12" label="30. VERDE"></v-radio>
                          <v-radio class="my-0 mx-3" hide-details="" :value="13" label="33. PERSONALIZADO"></v-radio>
                          <v-radio class="my-0 mx-3" hide-details="" :value="14" label="36. ILUSTRADO"></v-radio>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </div>
                </div>
                <v-checkbox class="mt-0" hide-details="" label="La pareja tiene logo / iniciales / etc..." v-model="pb.carteleriaPareja"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="La pareja aporta seating - detallar" v-model="pb.carteleriaSeating"></v-checkbox>
                <v-textarea v-if="pb.carteleriaSeating" filled dense label="" hide-details="" v-model="pb.otroFf"></v-textarea>
                <v-checkbox class="mt-0" hide-details="" label="Se encarga la pareja de toda la carteleria" v-model="pb.carteleriaParejaTodo"></v-checkbox>
                <v-divider class="my-2"></v-divider>
                <h2 class="primary--text">DECORACIÓN ESPACIOS</h2>
                <v-checkbox class="mt-0" hide-details="" label="Decoración floral (mesas) - detallar contacto:" v-model="pb.decoracionFloral"></v-checkbox>
                <v-textarea v-if="pb.decoracionFloral" filled dense label="" hide-details="" v-model="pb.decoracionDetallar"></v-textarea>
                <v-divider class="my-2"></v-divider>
                <h2 class="primary--text">DISTRIBUCIÓN INVITADOS</h2>
                <v-text-field filled dense label="Indicar número de personas en presidencia:" type="number" class="noArrow" hide-details="" v-model="pb.nPersonasPresidencia"></v-text-field>
                <v-textarea filled dense label="Otras observaciones de distribución:" hide-details="" v-model="pb.observacionesDistribucion"></v-textarea>
                <v-divider class="my-2"></v-divider>
                <h2 class="primary--text">MÚSICA</h2>
                <h4 style="font-weight: bold;">ENLACE CIVIL</h4>
                <v-radio-group
                class="mt-0"
                hide-details=""
                v-model="pb.musicaEnlace"
                column
                >
                  <div class="d-flex flex-row align-center">
                    <v-radio class="my-0 mx-3" hide-details="" :value="true" label="Se encarga MasFarré"></v-radio>
                    <v-radio class="my-0 ms-3" hide-details="" :value="false" label="Se encarga la pareja"></v-radio>
                  </div>
                </v-radio-group>
                <h4 style="font-weight: bold;">APERITIVO</h4>
                <v-radio-group
                class="mt-0"
                hide-details=""
                v-model="pb.musicaAperitivo"
                column
                >
                  <div class="d-flex flex-row align-center">
                    <v-radio class="my-0 mx-3" hide-details="" :value="true" label="Se encarga MasFarré"></v-radio>
                    <v-radio class="my-0 ms-3" hide-details="" :value="false" label="Se encarga la pareja"></v-radio>
                  </div>
                </v-radio-group>
                <h4 style="font-weight: bold;">SALÓN</h4>
                <v-radio-group
                class="mt-0"
                hide-details=""
                v-model="pb.musicaSalon"
                column
                >
                  <div class="d-flex flex-row align-center">
                    <v-radio class="my-0 mx-3" hide-details="" :value="true" label="Se encarga MasFarré"></v-radio>
                    <v-radio class="my-0 ms-3" hide-details="" :value="false" label="Se encarga la pareja"></v-radio>
                  </div>
                </v-radio-group>
                <h4 style="font-weight: bold;">DISCO MOVIL</h4>
                <v-radio-group
                class="mt-0"
                hide-details=""
                v-model="pb.musicaDisco"
                column
                >
                  <div class="d-flex flex-row align-center">
                    <v-radio class="my-0 mx-3" hide-details="" :value="true" label="Se encarga MasFarré"></v-radio>
                    <v-radio class="my-0 ms-3" hide-details="" :value="false" label="Se encarga la pareja"></v-radio>
                  </div>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6">
                <h2 class="primary--text">DETALLES INVITADOS (cuando se entregará)</h2>
                <h4 style="font-weight: bold;">DETALLE</h4>
                <v-radio-group
                class="mt-0"
                hide-details=""
                v-model="pb.detalle"
                column
                >
                  <div class="d-flex flex-row align-center">
                    <v-radio class="my-0 mx-3" hide-details="" :value="true" label="Si - ¿Qué es?"></v-radio>
                    <v-radio class="my-0 ms-3" hide-details="" :value="false" label="NO"></v-radio>
                  </div>
                </v-radio-group>
                <v-text-field class="mb-4 mt-3" v-if="pb.detalle" filled dense label="" hide-details="" v-model="pb.detalleQueEs"></v-text-field>
                <h4 style="font-weight: bold;">CUÁNDO SE ENTREGARÁ</h4>
                <v-radio-group
                class="mt-0"
                hide-details=""
                v-model="pb.cuandoDetalle"
                column
                >
                  <div class="d-flex flex-row align-center">
                    <v-radio class="my-0 mx-3" hide-details="" :value="true" label="Puesto en mesa"></v-radio>
                    <v-radio class="my-0 ms-3" hide-details="" :value="false" label="Durante los cafés"></v-radio>
                  </div>
                </v-radio-group>
                <h4 style="font-weight: bold;">QUIÉN LO ENTREGARÁ</h4>
                <v-radio-group
                class="mt-0"
                hide-details=""
                v-model="pb.quienDetalle"
                column
                >
                  <div class="d-flex flex-row align-center">
                    <v-radio class="my-0 mx-3" hide-details="" :value="true" label="Pareja"></v-radio>
                    <v-radio class="my-0 ms-3" hide-details="" :value="false" label="Otros"></v-radio>
                  </div>
                </v-radio-group>
                <v-divider class="my-2"></v-divider>
                <h2 class="primary--text">TÉCNICOS</h2>
                <v-text-field class="mb-4 mt-3" dense label="Empresas contratadas" hide-details="" v-model="pb.empresasContratadas"></v-text-field>
                <v-text-field class="mb-4 mt-3 noArrow" type="number" dense label="Total técnicos que comen" hide-details="" v-model="pb.tecnicosComen"></v-text-field>
                <h4 style="font-weight: bold;">REPORTAJE CON LOS INVITADOS:</h4>
                <v-radio-group
                class="mt-0"
                hide-details=""
                v-model="pb.reportaje"
                column
                >
                  <div class="d-flex flex-row align-center">
                    <v-radio class="my-0 mx-3" hide-details="" :value="1" label="Después del enlace"></v-radio>
                    <v-radio class="my-0 ms-3" hide-details="" :value="2" label="Después del aperitivo"></v-radio>
                    <v-radio class="my-0 ms-3" hide-details="" :value="3" label="En el salón"></v-radio>
                    <v-radio class="my-0 ms-3" hide-details="" :value="4" label="Por definir"></v-radio>
                    <v-radio class="my-0 ms-3" hide-details="" :value="5" label="No habrá"></v-radio>
                  </div>
                </v-radio-group>
                <v-divider class="my-2"></v-divider>
                <h2 class="primary--text">OTROS DETALLES</h2>
                <h4 class="primary--text">(contratados por la pareja)</h4>
                <v-checkbox class="mt-0" hide-details="" label="Proyección" v-model="pb.parejaProyeccion"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Photocall" v-model="pb.parejaPhotocall"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Fotomatón" v-model="pb.parejaFotomaton"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Otros" v-model="pb.parejaOtros"></v-checkbox>
                <v-text-field class="mb-4 mt-3 ms-8" v-if="pb.parejaOtros" dense label="Detallar otros" hide-details="" v-model="pb.parejaOtrosDetallado"></v-text-field>
                <v-checkbox class="mt-0" hide-details="" label="Parking" v-model="pb.parejaParking"></v-checkbox>
                <div class="ms-8" v-if="pb.parejaParking">
                  <v-text-field class="mb-4 mt-3" dense label="Necesidades" hide-details="" v-model="pb.parejaParkingNecesidades"></v-text-field>
                  <v-text-field class="mb-4 mt-3" dense label="Ubicación" hide-details="" v-model="pb.parejaParkingUbicacion"></v-text-field>
                </div>
                <v-checkbox class="mt-0" hide-details="" label="Bus" v-model="pb.parejaBus"></v-checkbox>
                <v-text-field class="mb-4 mt-3 ms-8" v-if="pb.parejaBus" dense label="Horario bus" hide-details="" v-model="pb.parejaBusHorario"></v-text-field>
                <v-divider class="my-2"></v-divider>
                <h2 class="primary--text">OTROS DETALLES</h2>
                <v-checkbox class="mt-0" hide-details="" label="Necesidad de suite nupcial" v-model="pb.suite"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Disfrutarán otro día de la suite" v-model="pb.otroDiaSuite"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="No necesitan habitaciones" v-model="pb.sinHabitaciones"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Necesitan habitaciones" v-model="pb.habitaciones"></v-checkbox>
                <v-text-field class="mb-4 mt-3 ms-8 noArrow" v-if="pb.habitaciones" type="number" dense label="Nº habitaciones" hide-details="" v-model="pb.nHabitaciones"></v-text-field>
                <h4 style="font-weight: bold;">(reservas@gmasfarre.com)</h4>
                <v-radio-group
                hide-details=""
                v-model="pb.bloqueo"
                column
                >
                  <v-radio class="my-0 mx-3" hide-details="" :value="true" label="Existe bloqueo previo"></v-radio>
                  <v-radio class="my-0 ms-3" hide-details="" :value="false" label="No hay bloqueo"></v-radio>
                </v-radio-group>
                <v-divider class="my-2"></v-divider>
                <h2 class="primary--text">OBSERVACIONES Y DETALLES EN GENERAL</h2>
                <v-textarea filled dense label="" hide-details="" v-model="pb.decoracionBodaALaCarta"></v-textarea>
              </v-col>
            </v-row>
          </template>
        </template>
        <template v-if="pb.ubBodaRealAux == 2">
          <template v-if="page == 1">
            <v-form ref="form1">
              <h1 class="primary--text mb-5">PAREJA</h1>
              <v-row>
                <v-col cols="12" md="6">
                  <h2 class="primary--text">DATOS</h2>
                  <div class="ms-5">
                    <v-text-field class="mb-4 mt-3" readonly dense label="NOMBRE PAREJA" hide-details="" :value="datos.datos.novios ? (datos.datos.novios.startsWith('PB ') ? datos.datos.novios.slice(3) : datos.datos.novios) : ''"></v-text-field>
                    <v-text-field class="mb-4 mt-3" readonly dense label="FECHA ENLACE" hide-details="" :value="datos.datos.fechaBodaReal"></v-text-field>
                  </div>
                  <h2 class="primary--text">ESPACIOS</h2>
                  <div class="ms-5">
                    <v-text-field class="mb-4 ms-5 mt-3" dense readonly label="LUGAR" hide-details="" :value="datos.datos.ubBodaReal"></v-text-field>
                    <v-text-field class="mb-4 ms-5 mt-3" dense label="APERITIVO" hide-details="" v-model="pb.aptvo"></v-text-field>
                    <v-text-field class="mb-4 ms-5 mt-3" dense label="BANQUETE" hide-details="" v-model="pb.banquete"></v-text-field>
                  </div>
                </v-col>
                <v-col cols="12" md="6">
                  <h2 class="primary--text">PRUEBA DE BODA</h2>
                  <div class="ms-5">
                    <v-text-field class="mb-4 mt-3" dense label="FECHA DEGUSTACIÓN" hide-details="" v-model="pb.fechaDegustacion"></v-text-field>
                    <v-text-field class="mb-4 mt-3 noArrow" :rules="[rules.req]" type="number" dense label="COMENSALES DÍA DEGUSTACIÓN*" hide-details="" v-model="pb.adultos"></v-text-field>
                    <v-textarea filled dense label="INTOLERANCIAS DÍA DEGUSTACIÓN" hide-details="" v-model="pb.novios"></v-textarea>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-textarea filled dense label="OBSERVACIONES" hide-details="" v-model="pb.observacionesPareja"></v-textarea>
                </v-col>
              </v-row>
              <v-divider class="my-2"></v-divider>
              <h1 class="primary--text mb-5">BODA</h1>
              <v-row>
                <v-col cols="12" md="6">
                  <h2 class="primary--text">PERSONAS BODA</h2>
                  <div class="ms-5">
                    <v-text-field class="mb-4 mt-3 noArrow" dense :rules="[rules.req]" type="number" label="ADULTOS*" hide-details="auto" v-model="pb.adultosBoda"></v-text-field>
                    <v-text-field class="mb-4 mt-3 noArrow" dense :rules="[rules.req]" type="number" label="NIÑOS*" hide-details="auto" v-model="pb.ninosBoda"></v-text-field>
                  </div>
                </v-col>
                <v-col cols="12" md="6">
                  <h2 class="primary--text">HORARIO</h2>
                  <div class="ms-5">
                    <v-text-field class="mb-4 mt-3" dense label="ENLACE" hide-details="" v-model="pb.hEnlace"></v-text-field>
                    <v-text-field class="mb-4 mt-3" dense label="APERITIVO" hide-details="" v-model="pb.hAptvo"></v-text-field>
                    <v-text-field class="mb-4 mt-3" dense label="COMIDA/CENA" hide-details="" v-model="pb.hComida"></v-text-field>
                    <v-text-field class="mb-4 mt-3" dense label="FIN EVENTO" hide-details="" v-model="pb.hFinEvento"></v-text-field>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-textarea filled dense label="OBSERVACIONES" hide-details="" v-model="pb.observacionesBoda"></v-textarea>
                </v-col>
              </v-row>
              <v-divider class="my-2"></v-divider>
              <v-row>
                <v-col cols="12">
                  <h2 class="primary--text">ENLACE & NECESIDADES</h2>
                  <v-radio-group
                    hide-details=""
                    v-model="pb.enlace"
                    column
                  >
                    <div class="ms-5">
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-radio hide-details="" :value="1" label="ENLACE RELIGIOSO"></v-radio>
                          <div v-if="pb.enlace == 1">
                            <v-text-field class="mb-4 mt-3" dense label="IGLESIA/POBLACIÓN" hide-details="" v-model="pb.iglesia"></v-text-field>
                          </div>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-radio hide-details="" :value="2" label="ENLACE CIVIL"></v-radio>
                          <v-text-field class="mb-4 mt-3" v-if="pb.enlace == 2" dense label="UBICACIÓN" hide-details="" v-model="pb.eUbicacion"></v-text-field>
                        </v-col>
                      </v-row>
                    </div>
                  </v-radio-group>
                  <h2 class="primary--text mt-3">GETIÓN MASFARRÉ</h2>
                  <div class="ms-5 d-flex">
                    <v-checkbox class="me-7" hide-details="" label="SILLAS" v-model="pb.mfSillas"></v-checkbox>
                    <v-checkbox class="me-7" hide-details="" label="MEGAFONÍA" v-model="pb.mfMegafonia"></v-checkbox>
                    <v-checkbox class="me-7" hide-details="" label="OTROS" v-model="pb.mfOtras"></v-checkbox>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-textarea filled dense label="OBSERVACIONES" hide-details="" v-model="pb.observacionesEnlace"></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </template>
          <template v-if="page == 2">
            <v-form ref="form2">
              <h1 class="primary--text mb-5">APERITIVO</h1>
              <v-row>
                <v-col cols="12" md="6">
                  <h2 class="primary--text">RECEPCIÓN</h2>
                  <v-checkbox class="mt-0" hide-details="" label="Cava de bienvenida" v-model="pb.cavaBienvenida"></v-checkbox>
                  <v-checkbox class="mt-0" hide-details="" label="Limonada" v-model="pb.limonada"></v-checkbox>
                </v-col>
                <v-col cols="12" md="6">
                  <h2 class="primary--text">ESPACIO BIENVENIDA</h2>
                  <v-text-field class="mb-4 mt-3" dense label="ESPACIO BIENVENIDA" hide-details="" v-model="pb.espacioBienvenida"></v-text-field>
                </v-col>
                <v-divider class="mb-3"></v-divider>
                <v-col cols="12" md="4">
                  <span class="d-flex">
                    <h2 class="primary--text">BUFFET</h2>
                    <h6 class="secondary--text ms-2">(ELEGIR 1 O 2)</h6>
                  </span>
                  <v-checkbox class="mt-0" hide-details="" label="Jamoncito del bueno" v-model="pb.jamoncito"></v-checkbox>
                  <div class="ms-8">
                    <h4 class="secondary--text">Jamón Ibérico de Guijuelo al corte</h4>
                    <h4 class="secondary--text">Pan cristal & tomate rosa</h4>
                  </div>
                  <v-checkbox class="mt-0" hide-details="" label="La quesería" v-model="pb.laQueseria"></v-checkbox>
                  <div class="ms-8">
                    <h4 class="secondary--text">Degustación de quesos de la zona</h4>
                    <h4 class="secondary--text">Mermeladas & frutos silvestres</h4>
                    <h4 class="secondary--text">Miller's toast - grissinis - picos campero</h4>
                  </div>
                  <v-checkbox class="mt-0" hide-details="" label="Pato" v-model="pb.pato"></v-checkbox>
                  <div class="ms-8">
                    <h4 class="secondary--text">Mil hojas de foie, membrillo, queso y manzana</h4>
                    <h4 class="secondary--text">Paté de campaña a las finas hierbas</h4>
                    <h4 class="secondary--text">Confitura de violetas - pan Guitatu - uvas</h4>
                  </div>
                  <v-checkbox class="mt-0" hide-details="" label="Vermutería" v-model="pb.vermuteria"></v-checkbox>
                  <div class="ms-8">
                    <h4 class="secondary--text">Chips & snacks</h4>
                    <h4 class="secondary--text">Olivas chupadedos & olivas arrugadas de Aragón</h4>
                    <h4 class="secondary--text">Encurtidos & Laterío "Barrecha"</h4>
                    <h4 class="secondary--text">Vermut casero macerado con naranja</h4>
                  </div>
                  <v-checkbox class="mt-0" hide-details="" label="Japonés" v-model="pb.japones"></v-checkbox>
                  <div class="ms-8">
                    <h4 class="secondary--text">SushiRolls - hosomakis - nigiris - sashimi moriawase</h4>
                    <h4 class="secondary--text">Ensalada de algas y sésamo</h4>
                    <h4 class="secondary--text">Soja - wasabi - jengib</h4>
                  </div>
                </v-col>
                <v-col cols="12" md="4">
                  <span class="d-flex">
                    <h2 class="primary--text">BUFFET</h2>
                    <h6 class="secondary--text ms-2">(ELEGIR 1 O 2)</h6>
                  </span>
                  <v-checkbox class="mt-0" hide-details="" label="Arroces del mundo" v-model="pb.arroces"></v-checkbox>
                  <div class="ms-8">
                    <h4 class="secondary--text">Fideuá de chipirones en dos texturas</h4>
                    <h4 class="secondary--text">Risotto de arroz carnaroli con ceps y trompetillas</h4>
                  </div>
                  <v-checkbox class="mt-0" hide-details="" label="Espetos" v-model="pb.espetos"></v-checkbox>
                  <div class="ms-8">
                    <h4 class="secondary--text">Pulpo y panceta a la parrilla</h4>
                    <h4 class="secondary--text">Chipirones frescos con su picada</h4>
                  </div>
                  <v-checkbox class="mt-0" hide-details="" label="Take away" v-model="pb.takeAway"></v-checkbox>
                  <div class="ms-8">
                    <h4 class="secondary--text">Gigot de ternera - tupinambo - patatas paja</h4>
                    <h4 class="secondary--text">Gulas a la Bilbana y crackers</h4>
                  </div>
                  <v-checkbox class="mt-0" hide-details="" label="Km.0" v-model="pb.km0"></v-checkbox>
                  <div class="ms-8">
                    <h4 class="secondary--text">Huevos estrellados</h4>
                    <h4 class="secondary--text">Migas de pastor</h4>
                  </div>
                  <v-checkbox class="mt-0" hide-details="" label="Tacos bar" v-model="pb.tacosBar"></v-checkbox>
                  <div class="ms-8">
                    <h4 class="secondary--text">Pulled pork - barbacoa - jalapeños</h4>
                    <h4 class="secondary--text">Mini kebab de pollo desmechado</h4>
                  </div>
                  <v-checkbox class="mt-0" hide-details="" label="Cocina en directo" v-model="pb.cocinaEnDirecto"></v-checkbox>
                  <div class="ms-8">
                    <h4 class="secondary--text">Magret de pato - reducción PC - confitura cereza</h4>
                    <h4 class="secondary--text">Gamba de Palamós a la plancha</h4>
                  </div>
                </v-col>
                <v-col cols="12" md="4">
                  <span class="d-flex">
                    <h2 class="primary--text">BUFFET</h2>
                    <h6 class="secondary--text ms-2">(NO INCLUIDO)</h6>
                  </span>
                  <v-checkbox class="mt-0" hide-details="" label="Pulpeiro" v-model="pb.pulpeiro"></v-checkbox>
                  <div class="ms-8">
                    <h4 class="secondary--text">Pulpo a la gallega - patata . alioli de pimentón</h4>
                  </div>
                  <v-checkbox class="mt-0" hide-details="" label="Marinero" v-model="pb.marinero"></v-checkbox>
                  <div class="ms-8">
                    <h4 class="secondary--text">Gambas a la sal - canaillas - mejillones a la americana - langostinos - berberechos</h4>
                  </div>
                  <v-checkbox class="mt-0" hide-details="" label="Ahumados" v-model="pb.ahumados"></v-checkbox>
                  <div class="ms-8">
                    <h4 class="secondary--text">salmón noruego</h4>
                    <h4 class="secondary--text">Bacalao Islandia</h4>
                    <h4 class="secondary--text">Salsa tártara, confitura de pimiento asado, japosalsa</h4>
                  </div>
                  <v-checkbox class="mt-0" hide-details="" label="Puro show" v-model="pb.puroShow"></v-checkbox>
                  <div class="ms-8">
                    <h4 class="secondary--text">Chuletón Vaca madurada 40 días Summatura</h4>
                    <h4 class="secondary--text">Patata asada, pimientos de Lodosa y selección de mostazas</h4>
                  </div>
                  <!-- <v-divider class="mb-3"></v-divider>
                  <h2 class="primary--text">OTRAS DELICATESSEN</h2>
                  <h4 class="primery--text">(ELEGIR 6)</h4>
                  
  
                  <v-divider class="my-2"></v-divider>
                  <h2 class="primary--text">BAR</h2>
                  <v-checkbox class="mt-0" hide-details="" label="Aguas, Refrescos, Cervezas, Vermut" v-model="pb.bar"></v-checkbox>
                  <v-divider class="my-2"></v-divider>
                  <h2 class="primary--text">BODEGA</h2>
                  <v-checkbox class="mt-0" hide-details="" label="Vinos DO Somontano (blanco, rosado y tinto)" v-model="pb.bar"></v-checkbox>
                  <v-divider class="my-2"></v-divider>
                  <h2 class="primary--text">CERVECEANDO (no incluido)</h2>
                  <v-checkbox class="mt-0" hide-details="" label="Selección de cervezas nacionales o intenacionales" v-model="pb.bar"></v-checkbox>
                  <v-divider class="my-2"></v-divider>
                  <h2 class="primary--text">OBSERVACIONES APERITIVO:</h2>
                  <v-textarea filled dense hide-details="" v-model="pb.obsAptvo"></v-textarea> -->
                </v-col>
                <v-col cols="12">
                  <v-textarea filled dense label="OBSERVACIONES" hide-details="" v-model="pb.observacionesBuffet"></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </template>
          <template v-if="page == 3">
            <v-form ref="form3">
              <h1 class="primary--text">?</h1>
              <v-row>
                <v-col cols="12" class="pb-0">
                  <span class="d-flex">
                    <h2 class="primary--text">OTRAS DELICATESSEN</h2>
                    <h6 class="secondary--text ms-2">(ELEGIR 6)</h6>
                  </span>
                </v-col>
                <v-col cols="12" md="6" class="pt-0">
                  <v-checkbox class="mt-0" hide-details="" label="Air baguette de jamón ibérico de Guijuelo" v-model="pb.air"></v-checkbox>
                  <v-checkbox class="mt-0" hide-details="" label="Ssäm de pollo crispy con lima y chile" v-model="pb.ssam"></v-checkbox>
                  <v-checkbox class="mt-0" hide-details="" label="Mollete de bonito pibil" v-model="pb.mollete"></v-checkbox>
                  <v-checkbox class="mt-0" hide-details="" label="Croquetas de pollo a la chilindrón" v-model="pb.croquetas"></v-checkbox>
                  <v-checkbox class="mt-0" hide-details="" label="Bikini de berenjena trufada" v-model="pb.bikini"></v-checkbox>
                  <v-checkbox class="mt-0" hide-details="" label="Mas brava mas" v-model="pb.bravas"></v-checkbox>
                  <v-checkbox class="mt-0" hide-details="" label="Tataki de atún, vinagreta de soja y ensalada wakame" v-model="pb.tataki"></v-checkbox>
                  <v-checkbox class="mt-0" hide-details="" label="Torreznos con alioli de lima" v-model="pb.torreznos"></v-checkbox>
                  <v-checkbox class="mt-0" hide-details="" label="Bao de confit de pato salsa hoisin y cebolla roja" v-model="pb.bao"></v-checkbox>
                  <v-checkbox class="mt-0" hide-details="" label="Huevo poché - sobrasada - trufa de verano" v-model="pb.huevoPache"></v-checkbox>
                </v-col>
                <v-col cols="12" md="6" class="pt-0">
                  <v-checkbox class="mt-0" hide-details="" label="Mini croissant de costilla ahumada" v-model="pb.mini"></v-checkbox>
                  <v-checkbox class="mt-0" hide-details="" label="Crujiente de manitas de cerdo y tartar de gamba roja" v-model="pb.crujiente"></v-checkbox>
                  <v-checkbox class="mt-0" hide-details="" label="Zamburiñas gallegas gratinadas" v-model="pb.zamburinas"></v-checkbox>
                  <v-checkbox class="mt-0" hide-details="" label="Brioche de rabo de buey con cebolla frita" v-model="pb.brioche"></v-checkbox>
                  <v-checkbox class="mt-0" hide-details="" label="Arroz meloso de pato, rosignol, reducción de PX" v-model="pb.arroz"></v-checkbox>
                  <v-checkbox class="mt-0" hide-details="" label="Tartar de atún con wanton y guacamole" v-model="pb.tartar"></v-checkbox>
                  <v-checkbox class="mt-0" hide-details="" label="Coca de roastbeef, germinados & tártara" v-model="pb.coca"></v-checkbox>
                  <v-checkbox class="mt-0" hide-details="" label="Anchoa - concassé de tomate rosa - pan fino" v-model="pb.anchoa"></v-checkbox>
                  <v-checkbox class="mt-0" hide-details="" label="Albóndigas de ciervo glaseadas" v-model="pb.albondigas"></v-checkbox>
                  <v-checkbox class="mt-0" hide-details="" label="Borraja en tempura y miel" v-model="pb.borraja"></v-checkbox>
                </v-col>
              </v-row>
              <v-divider class="my-2"></v-divider>
              <h1 class="primary--text">BAR & BODEGA</h1>
              <v-row>
                <v-col cols="12" md="4">
                  <span class="d-flex">
                    <h2 class="primary--text">BAR</h2>
                  </span>
                  <v-checkbox class="mt-0" hide-details="" label="Cervezas" v-model="pb.cervezas"></v-checkbox>
                  <v-checkbox class="mt-0" hide-details="" label="Vermut" v-model="pb.vermut"></v-checkbox>
                  <v-checkbox class="mt-0" hide-details="" label="Limonada casera" v-model="pb.limonadaCasera"></v-checkbox>
                  <v-checkbox class="mt-0" hide-details="" label="Refrescos" v-model="pb.refrescos"></v-checkbox>
                  <v-checkbox class="mt-0" hide-details="" label="Aguas" v-model="pb.aguas"></v-checkbox>
                </v-col>
                <v-col cols="12" md="4">
                  <span class="d-flex">
                    <h2 class="primary--text">BODEGA</h2>
                  </span>
                  <v-checkbox class="mt-0" hide-details="" label="Vinos Bodega DO Somontano" v-model="pb.vinos"></v-checkbox>
                </v-col>
                <v-col cols="12" md="4">
                  <span class="d-flex">
                    <h2 class="primary--text">OKTOBERFEST</h2>
                    <h6 class="secondary--text ms-2">(NO INCLUIDO)</h6>
                  </span>
                  <v-checkbox class="mt-0" hide-details="" label="Selección de cervezas nacionales o internacionales" v-model="pb.oktoberfest"></v-checkbox>
                </v-col>
              </v-row>
            </v-form>
          </template>
          <template v-if="page == 4">
            <h1 class="primary--text mb-5">MENÚ</h1>
            <v-row>
              <v-col cols="12" md="6">
                <h2 class="primary--text">PARA EMPEZAR (no incluido)</h2>
                <v-checkbox class="mt-0" hide-details="" label="Salmorejo de tomate rosa y cerezas, concassé de piña y langostino" v-model="pb.salmorejo"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Crema de carabineros, vieiras a la plancha y jamón ibérico" v-model="pb.cremaCarabineros"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Sopa de melón cantalupo, tartar de atún rojo y huevas de tobiko" v-model="pb.sopaMelon"></v-checkbox>
                <v-divider class="my-2"></v-divider>
                <h2 class="primary--text">PRIMER PLATO (elegir tres)</h2>
                <v-checkbox class="mt-0" hide-details="" label="Ensalada de pulpo y langostinos, escabeche de shitakes con vinagreta de mango-maracuyá" v-model="pb.ensaladaPulpo"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Cannelloni de pasta fresca con presa ibérica, trompeta negra, rosignol y rosignol" v-model="pb.canneloni"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Ravioli de txangurro y carabineros con emulsión de gamba roja" v-model="pb.ravioli"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Canelón de rape y langostinos con royal de cigalas" v-model="pb.canelonDeRape"></v-checkbox>
  
                <v-checkbox hide-details="" label="Pulpo braseado - cremoso de patata - alioli de pimentón - guacamole" v-model="pb.pulpoBraseado"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Merluza con marinera de sepia y pulpo y gratén de boletus" v-model="pb.merluza"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Bacalao 70º con tradicional salsa verde, langostino y crujiente de gambas y almendras" v-model="pb.bacalao"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Rodaballo con chipirones en su tinta y muselina de gamba roja" v-model="pb.menuRodaballo"></v-checkbox>
  
                <v-checkbox hide-details="" label="Ensalada de bogavante gallego y tartar de cítricos" v-model="pb.ensaladaBogavante"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Bogavante gallego asado a la parrilla" v-model="pb.bogavante"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Langosta imperial en dos texturas (media)" v-model="pb.langostaImperial"></v-checkbox>
                <v-divider class="my-2"></v-divider>
                <h2 class="primary--text">SORBETES (no incluido)</h2>
                <v-checkbox class="mt-0" hide-details="" label="Mandarina" v-model="pb.mandarina"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Mango&Gewürztraminer" v-model="pb.mangogewurztraminer"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Pink Prite" v-model="pb.pinkPrite"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Aperol Rosé" v-model="pb.aperol"></v-checkbox>
                <v-divider class="my-2"></v-divider>
              </v-col>
              <v-col cols="12" md="6">
                <h2 class="primary--text">SEGUNDO PLATO (elegir tres)</h2>
                <v-checkbox class="mt-0" hide-details="" label="Jarrete de lechazo al homo, con romero y patata rota" v-model="pb.jarreteDeLechazo"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Paletila de ternasco asado al modo tradicional con patatas panadera" v-model="pb.paletillaTernasco"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Ternasco glaseado a baja temperatura - patata trufada - boniato" v-model="pb.ternascoGlaseado"></v-checkbox>
                <v-checkbox hide-details="" label="Solomillo de ternera - cabernet Sauvignon - tupinambo - manzana" v-model="pb.solomilloTerneraReduccion"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Solomillo de ternera - escalopa de foile, cremoso de patata y oporto" v-model="pb.solomilloTerneraEscalopa"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Paletilla de cabrito con patatas panadera, romero, ajo y perejil" v-model="pb.paletillaDeCabrito"></v-checkbox>
                <v-divider class="my-2"></v-divider>
                <h2 class="primary--text">POSTRES (se degustarán todos)</h2>
                <v-checkbox readonly class="mt-0" hide-details="" :value="true" label="Torrija artesana con helado de vainilla de Madagascar"></v-checkbox>
                <v-checkbox readonly class="mt-0" hide-details="" :value="true" label="Lemon Pie & merengue y sopita de frambuesa"></v-checkbox>
                <v-checkbox readonly class="mt-0" hide-details="" :value="true" label="Bombón helado de chocolate & praliné"></v-checkbox>
                <v-checkbox readonly class="mt-0" hide-details="" :value="true" label="Galaxia de chocolate y sus satélites"></v-checkbox>
                <v-checkbox readonly class="mt-0" hide-details="" :value="true" label="Cremme Brullee con Ferrero Rocher"></v-checkbox>
                <v-checkbox readonly class="mt-0" hide-details="" :value="true" label="Lingote de contrastes y crema de moka"></v-checkbox>
                <v-checkbox readonly class="mt-0" hide-details="" :value="true" label="Tropic Passion y crema de chocolate con yogurt"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" v-model="pb.presentacionTarta" label="Presentación de tarta"></v-checkbox>
                <v-divider class="my-2"></v-divider>
                <h2 class="primary--text">BODEGA & CAVA</h2>
                <v-row>
                  <v-col cols="12" md="4">
                    <h4 class="secondary--text">BLANCOS</h4>
                    <v-checkbox class="mt-0" hide-details="" v-model="pb.lausChardonnay" label="Laus chardonnay"></v-checkbox>
                    <v-checkbox class="mt-0" hide-details="" v-model="pb.pirineos3404" label="Pirineos 3404"></v-checkbox>
                    <v-checkbox class="mt-0" hide-details="" v-model="pb.lauraLalanne" label="Laura Lalanne"></v-checkbox>
                    <v-checkbox class="mt-0" hide-details="" v-model="pb.enate234Chardonnay" label="Enate 234 Chardonnay"></v-checkbox>
                    <v-checkbox class="mt-0" hide-details="" v-model="pb.vinasDelVeroGewurztraminer" label="Viñas del Vero Gewürztraminer"></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="4">
                    <h4 class="secondary--text">TINTOS</h4>
                    <v-checkbox class="mt-0" hide-details="" v-model="pb.vinasDelVeroCrianza" label="Viñas del Vero Crianza"></v-checkbox>
                    <v-checkbox class="mt-0" hide-details="" v-model="pb.lausCrianza" label="Laus Crianza"></v-checkbox>
                    <v-checkbox class="mt-0" hide-details="" v-model="pb.lalanneCrianza" label="Lalanne Crianza"></v-checkbox>
                    <v-checkbox class="mt-0" hide-details="" v-model="pb.enateCrianza" label="Enate Crianza"></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="4">
                    <h4 class="secondary--text">CAVAS</h4>
                    <v-checkbox class="mt-0" hide-details="" v-model="pb.cavaReyesAragon" label="Cava reyes de Argón Brut"></v-checkbox>
                    <v-checkbox class="mt-0" hide-details="" v-model="pb.freixenetVintageReservaBrut" label="Freixenet Vintage Reserva Brut"></v-checkbox>
                    <v-checkbox class="mt-0" hide-details="" v-model="pb.lucreciaLalanneBrut" label="Lucrecia Lalanne Brut"></v-checkbox>
                    <v-checkbox class="mt-0" hide-details="" v-model="pb.agustiTorelloMataBrutNature" label="Agustí Torelló&Mata Brut Nature"></v-checkbox>
                    <v-checkbox class="mt-0" hide-details="" v-model="pb.cavaJuveCampsResservaDeFamilia" label="Cava Juvé&Camps Reserva de Familia"></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-textarea filled dense label="OBSERVACIONES" hide-details="" v-model="pb.observacionesMenu"></v-textarea>
              </v-col>
            </v-row>
          </template>
          <template v-if="page == 5">
            <!-- <h1 class="primary--text mb-5">BODEGA</h1>
            <v-row>
              <v-col cols="12" md="4">
                <h2 class="primary--text">BLANCOS</h2>
                <v-checkbox class="mt-0" hide-details="" label="Viñas del Vero Macabeo-Chardonnay" v-model="pb.blancoVdvMacabeo"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Laus Chardonnay" v-model="pb.blancoLaus"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Pirineos 3404" v-model="pb.blancoPirineo"></v-checkbox>
                <v-checkbox hide-details="" label="Laura Lalanne" v-model="pb.blancoLaura"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Enate 234 Chardonnay" v-model="pb.blacoEnate"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Viñas del Vero Gewürztraminer" v-model="pb.blancoVdvGewurz"></v-checkbox>
              </v-col>
              <v-col cols="12" md="4">
                <h2 class="primary--text">TINTOS</h2>
                <v-checkbox class="mt-0" hide-details="" label="Viñas del Vero Crianza" v-model="pb.tintoVdv"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Laus Crianza" v-model="pb.tintoLaus"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="De Beroz Crianza" v-model="pb.tintoBeroz"></v-checkbox>
                <v-checkbox hide-details="" label="Lalanne Crianza" v-model="pb.tintoLalanne"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Enate Crianza" v-model="pb.tintoEnate"></v-checkbox>
              </v-col>
              <v-col cols="12" md="4">
                <h2 class="primary--text">CAVAS</h2>
                <v-checkbox class="mt-0" hide-details="" label="Cava Reyes de Aragón Brut" v-model="pb.cavaReyes"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Freixenet Vintage Reserva Brut" v-model="pb.cavaFreixenet"></v-checkbox>
                <v-checkbox hide-details="" label="Lucrecia Lalanne Brut" v-model="pb.cavaLucrecia"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Agustí Torelló&Mata Brut Nature" v-model="pb.cavaAgusti"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Cava Juvé&Camps reserva de Familia" v-model="pb.cavaJuve"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Otra bodega o Cava (detallar)" v-model="pb.otroCava"></v-checkbox>
                <v-textarea v-if="pb.otroCava" filled dense label="Otra Bodega" hide-details="" v-model="pb.otroCavaDetallado"></v-textarea>
              </v-col>
            </v-row> -->
            <h1 class="primary--text mb-5">OTROS MENÚS</h1>
            <v-row>
              <v-col cols="12">
                <h2 class="primary--text">MENÚ INFANTIL</h2>
                <v-checkbox class="mt-0" hide-details="" label="Macarrones con tomate casero" v-model="pb.infMacarrones"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Macarrones blancos" v-model="pb.infMacarronesBlancos"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Añadir canelones de carne gratinados" v-model="pb.infCanelones"></v-checkbox>
                <v-checkbox hide-details="" label="Finguers de pollo con patatas fritas" v-model="pb.infFingers"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Longaniza a la brasa con patatas fritas" v-model="pb.infLonganiza"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Hamburguesa al plato con patatas" v-model="pb.infHamburguesa"></v-checkbox>
                <v-checkbox hide-details="" label="Guardería" v-model="pb.guarderia"></v-checkbox>
                <v-textarea filled dense label="Intolerancias y observaciones" hide-details="" v-model="pb.infObs"></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <h2 class="primary--text my-5">MENÚS ESPECIALES (detallar)</h2>
                <v-textarea filled dense hide-details="" label="MENÚS ESPECIALES" v-model="pb.menuEspecial"></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea filled dense hide-details="" label="OBSERVACIONES" v-model="pb.observacionesOtrosMenus"></v-textarea>
              </v-col>
            </v-row>
          </template>
          <template v-if="page == 6">
            <h1 class="primary--text mb-5">FIN DE FIESTA</h1>
            <v-row>
              <v-col cols="12" md="6">
                <h2 class="primary--text">BARRA LIBRE</h2>
                <v-checkbox class="mt-0" hide-details="" label="Licores en mesa" v-model="pb.ffLicores"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Barra llibre" v-model="pb.ffBarra"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Otros licores - detallar:" v-model="pb.ffOtros"></v-checkbox>
                <v-textarea v-if="pb.ffOtros" filled dense label="" hide-details="" v-model="pb.otroFf"></v-textarea>
                <v-divider class="my-2"></v-divider>
                <h2 class="primary--text">RECENA</h2>
                <v-radio-group
                  class="mt-0"
                  hide-details=""
                  v-model="pb.recena"
                  column
                  >
                  <v-radio class="my-0" hide-details="" :value="1" label="Tradicional"></v-radio>
                  <template v-if="pb.recena == 1">
                    <v-checkbox class="mt-0" hide-details="" label="Panini de bacon y queso" v-model="pb.paniniBaconQueso"></v-checkbox>
                    <v-checkbox class="mt-0" hide-details="" label="Coca de longaniza de Graus" v-model="pb.cocaLonganiza"></v-checkbox>
                    <v-checkbox class="mt-0" hide-details="" label="Tortilla de patata" v-model="pb.tortillaPatata"></v-checkbox>
                    <v-checkbox class="mt-0" hide-details="" label="Mini donuts & chocolate caliente" v-model="pb.miniDonuts"></v-checkbox>
                  </template>
                  <v-radio class="my-0" hide-details="" :value="2" label="Especial"></v-radio>
                  <template v-if="pb.recena == 2">
                    <v-checkbox class="mt-0" hide-details="" label="Buffet de variedad de tortillas" v-model="pb.recenaEspecialBuffetTortilla"></v-checkbox>
                    <v-checkbox class="mt-0" hide-details="" label="Buffet de sándwiches (Mixto, vegetal y Club)" v-model="pb.recenaEspecialBuffetSand"></v-checkbox>
                    <v-checkbox class="mt-0" hide-details="" label="Hotdogs con mostaza y kétchup" v-model="pb.recenaEspecialHotdogs"></v-checkbox>
                    <v-checkbox class="mt-0" hide-details="" label="Mini burguer" v-model="pb.recenaEspecialMiniBurguer"></v-checkbox>
                    <v-checkbox class="mt-0" hide-details="" label="Pizzas" v-model="pb.recenaEspecialPizzas"></v-checkbox>
                    <v-checkbox class="mt-0" hide-details="" label="Barbecue (longaniza, chorizo y panceta)" v-model="pb.recenaEspecialBbq"></v-checkbox>
                    <v-checkbox class="mt-0" hide-details="" label="Brownie y Tarta de zanahoria" v-model="pb.recenaEspecialBrownie"></v-checkbox>
                    <v-checkbox class="mt-0" hide-details="" label="Palomitas y Candy Bar" v-model="pb.recenaEspecialPalomitas"></v-checkbox>
                    <v-checkbox class="mt-0" hide-details="" label="Jamón al corte" v-model="pb.recenaEspecialJamón"></v-checkbox>
                  </template>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-divider class="my-2"></v-divider>
            <h1 class="primary--text mb-5">BODA A MEDIDA</h1>
            <v-row>
              <v-col cols="12" md="6">
                <h2 class="primary--text">CARTELERÍA</h2>
                <h4 class="primary--text">(Tarjeta Aperitivo - Tarjeta Menú - Mesero - Seating)</h4>
                <div class="d-flex flex-row">
                  <v-checkbox class="mt-0" hide-details="" label="Modelo MasFarré (detallar modelo):" v-model="pb.carteleriaMf"></v-checkbox>
                  <div class="ms-5" v-if="pb.carteleriaMf">
                    <v-radio-group
                    class="mt-0"
                    hide-details=""
                    v-model="pb.modeloCarteleria"
                    column
                    >
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-radio class="my-0 mx-3" hide-details="" :value="1" label="03. AZUL"></v-radio>
                          <v-radio class="my-0 mx-3" hide-details="" :value="2" label="06. LOGO PERSONALIZADO"></v-radio>
                          <v-radio class="my-0 mx-3" hide-details="" :value="3" label="09. FLORES MORADAS"></v-radio>
                          <v-radio class="my-0 mx-3" hide-details="" :value="4" label="12. LAVANDA"></v-radio>
                          <v-radio class="my-0 mx-3" hide-details="" :value="5" label="15. VERDE AGUA"></v-radio>
                          <v-radio class="my-0 mx-3" hide-details="" :value="6" label="18. ELEGANTE"></v-radio>
                          <v-radio class="my-0 mx-3" hide-details="" :value="7" label="03. AZUL"></v-radio>
                          <v-radio class="my-0 mx-3" hide-details="" :value="8" label="03. AZUL"></v-radio>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-radio class="my-0 mx-3" hide-details="" :value="9" label="21. PRIMAVERA"></v-radio>
                          <v-radio class="my-0 mx-3" hide-details="" :value="10" label="24. FLORES AZULES"></v-radio>
                          <v-radio class="my-0 mx-3" hide-details="" :value="11" label="27. EUCALIPTO"></v-radio>
                          <v-radio class="my-0 mx-3" hide-details="" :value="12" label="30. VERDE"></v-radio>
                          <v-radio class="my-0 mx-3" hide-details="" :value="13" label="33. PERSONALIZADO"></v-radio>
                          <v-radio class="my-0 mx-3" hide-details="" :value="14" label="36. ILUSTRADO"></v-radio>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </div>
                </div>
                <v-checkbox class="mt-0" hide-details="" label="La pareja tiene logo / iniciales / etc..." v-model="pb.carteleriaPareja"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="La pareja aporta seating - detallar" v-model="pb.carteleriaSeating"></v-checkbox>
                <v-textarea v-if="pb.carteleriaSeating" filled dense label="" hide-details="" v-model="pb.otroFf"></v-textarea>
                <v-checkbox class="mt-0" hide-details="" label="Se encarga la pareja de toda la carteleria" v-model="pb.carteleriaParejaTodo"></v-checkbox>
                <v-divider class="my-2"></v-divider>
                <h2 class="primary--text">DECORACIÓN ESPACIOS</h2>
                <v-checkbox class="mt-0" hide-details="" label="Decoración floral (mesas) - detallar contacto:" v-model="pb.decoracionFloral"></v-checkbox>
                <v-textarea v-if="pb.decoracionFloral" filled dense label="" hide-details="" v-model="pb.decoracionDetallar"></v-textarea>
                <v-divider class="my-2"></v-divider>
                <h2 class="primary--text">DISTRIBUCIÓN INVITADOS</h2>
                <v-text-field filled dense label="Indicar número de personas en presidencia:" type="number" class="noArrow" hide-details="" v-model="pb.nPersonasPresidencia"></v-text-field>
                <v-textarea filled dense label="Otras observaciones de distribución:" hide-details="" v-model="pb.observacionesDistribucion"></v-textarea>
                <v-divider class="my-2"></v-divider>
                <h2 class="primary--text">MÚSICA</h2>
                <h4 style="font-weight: bold;">ENLACE CIVIL</h4>
                <v-radio-group
                class="mt-0"
                hide-details=""
                v-model="pb.musicaEnlace"
                column
                >
                  <div class="d-flex flex-row align-center">
                    <v-radio class="my-0 mx-3" hide-details="" :value="true" label="Se encarga MasFarré"></v-radio>
                    <v-radio class="my-0 ms-3" hide-details="" :value="false" label="Se encarga la pareja"></v-radio>
                  </div>
                </v-radio-group>
                <h4 style="font-weight: bold;">APERITIVO</h4>
                <v-radio-group
                class="mt-0"
                hide-details=""
                v-model="pb.musicaAperitivo"
                column
                >
                  <div class="d-flex flex-row align-center">
                    <v-radio class="my-0 mx-3" hide-details="" :value="true" label="Se encarga MasFarré"></v-radio>
                    <v-radio class="my-0 ms-3" hide-details="" :value="false" label="Se encarga la pareja"></v-radio>
                  </div>
                </v-radio-group>
                <h4 style="font-weight: bold;">SALÓN</h4>
                <v-radio-group
                class="mt-0"
                hide-details=""
                v-model="pb.musicaSalon"
                column
                >
                  <div class="d-flex flex-row align-center">
                    <v-radio class="my-0 mx-3" hide-details="" :value="true" label="Se encarga MasFarré"></v-radio>
                    <v-radio class="my-0 ms-3" hide-details="" :value="false" label="Se encarga la pareja"></v-radio>
                  </div>
                </v-radio-group>
                <h4 style="font-weight: bold;">DISCO MOVIL</h4>
                <v-radio-group
                class="mt-0"
                hide-details=""
                v-model="pb.musicaDisco"
                column
                >
                  <div class="d-flex flex-row align-center">
                    <v-radio class="my-0 mx-3" hide-details="" :value="true" label="Se encarga MasFarré"></v-radio>
                    <v-radio class="my-0 ms-3" hide-details="" :value="false" label="Se encarga la pareja"></v-radio>
                  </div>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6">
                <h2 class="primary--text">DETALLES INVITADOS (cuando se entregará)</h2>
                <h4 style="font-weight: bold;">DETALLE</h4>
                <v-radio-group
                class="mt-0"
                hide-details=""
                v-model="pb.detalle"
                column
                >
                  <div class="d-flex flex-row align-center">
                    <v-radio class="my-0 mx-3" hide-details="" :value="true" label="Si - ¿Qué es?"></v-radio>
                    <v-radio class="my-0 ms-3" hide-details="" :value="false" label="NO"></v-radio>
                  </div>
                </v-radio-group>
                <v-text-field class="mb-4 mt-3" v-if="pb.detalle" filled dense label="" hide-details="" v-model="pb.detalleQueEs"></v-text-field>
                <h4 style="font-weight: bold;">CUÁNDO SE ENTREGARÁ</h4>
                <v-radio-group
                class="mt-0"
                hide-details=""
                v-model="pb.cuandoDetalle"
                column
                >
                  <div class="d-flex flex-row align-center">
                    <v-radio class="my-0 mx-3" hide-details="" :value="true" label="Puesto en mesa"></v-radio>
                    <v-radio class="my-0 ms-3" hide-details="" :value="false" label="Durante los cafés"></v-radio>
                  </div>
                </v-radio-group>
                <h4 style="font-weight: bold;">QUIÉN LO ENTREGARÁ</h4>
                <v-radio-group
                class="mt-0"
                hide-details=""
                v-model="pb.quienDetalle"
                column
                >
                  <div class="d-flex flex-row align-center">
                    <v-radio class="my-0 mx-3" hide-details="" :value="true" label="Pareja"></v-radio>
                    <v-radio class="my-0 ms-3" hide-details="" :value="false" label="Otros"></v-radio>
                  </div>
                </v-radio-group>
                <v-divider class="my-2"></v-divider>
                <h2 class="primary--text">TÉCNICOS</h2>
                <v-text-field class="mb-4 mt-3" dense label="Empresas contratadas" hide-details="" v-model="pb.empresasContratadas"></v-text-field>
                <v-text-field class="mb-4 mt-3 noArrow" type="number" dense label="Total técnicos que comen" hide-details="" v-model="pb.tecnicosComen"></v-text-field>
                <h4 style="font-weight: bold;">REPORTAJE CON LOS INVITADOS:</h4>
                <v-radio-group
                class="mt-0"
                hide-details=""
                v-model="pb.reportaje"
                column
                >
                  <div class="d-flex flex-row align-center">
                    <v-radio class="my-0 mx-3" hide-details="" :value="1" label="Después del enlace"></v-radio>
                    <v-radio class="my-0 ms-3" hide-details="" :value="2" label="Después del aperitivo"></v-radio>
                    <v-radio class="my-0 ms-3" hide-details="" :value="3" label="En el salón"></v-radio>
                    <v-radio class="my-0 ms-3" hide-details="" :value="4" label="Por definir"></v-radio>
                    <v-radio class="my-0 ms-3" hide-details="" :value="5" label="No habrá"></v-radio>
                  </div>
                </v-radio-group>
                <v-divider class="my-2"></v-divider>
                <h2 class="primary--text">OTROS DETALLES</h2>
                <h4 class="primary--text">(contratados por la pareja)</h4>
                <v-checkbox class="mt-0" hide-details="" label="Proyección" v-model="pb.parejaProyeccion"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Photocall" v-model="pb.parejaPhotocall"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Fotomatón" v-model="pb.parejaFotomaton"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Otros" v-model="pb.parejaOtros"></v-checkbox>
                <v-text-field class="mb-4 mt-3 ms-8" v-if="pb.parejaOtros" dense label="Detallar otros" hide-details="" v-model="pb.parejaOtrosDetallado"></v-text-field>
                <v-checkbox class="mt-0" hide-details="" label="Parking" v-model="pb.parejaParking"></v-checkbox>
                <div class="ms-8" v-if="pb.parejaParking">
                  <v-text-field class="mb-4 mt-3" dense label="Necesidades" hide-details="" v-model="pb.parejaParkingNecesidades"></v-text-field>
                  <v-text-field class="mb-4 mt-3" dense label="Ubicación" hide-details="" v-model="pb.parejaParkingUbicacion"></v-text-field>
                </div>
                <v-checkbox class="mt-0" hide-details="" label="Bus" v-model="pb.parejaBus"></v-checkbox>
                <v-text-field class="mb-4 mt-3 ms-8" v-if="pb.parejaBus" dense label="Horario bus" hide-details="" v-model="pb.parejaBusHorario"></v-text-field>
                <v-divider class="my-2"></v-divider>
                <h2 class="primary--text">OTROS DETALLES</h2>
                <v-checkbox class="mt-0" hide-details="" label="Necesidad de suite nupcial" v-model="pb.suite"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Disfrutarán otro día de la suite" v-model="pb.otroDiaSuite"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="No necesitan habitaciones" v-model="pb.sinHabitaciones"></v-checkbox>
                <v-checkbox class="mt-0" hide-details="" label="Necesitan habitaciones" v-model="pb.habitaciones"></v-checkbox>
                <v-text-field class="mb-4 mt-3 ms-8 noArrow" v-if="pb.habitaciones" type="number" dense label="Nº habitaciones" hide-details="" v-model="pb.nHabitaciones"></v-text-field>
                <h4 style="font-weight: bold;">(reservas@gmasfarre.com)</h4>
                <v-radio-group
                hide-details=""
                v-model="pb.bloqueo"
                column
                >
                  <v-radio class="my-0 mx-3" hide-details="" :value="true" label="Existe bloqueo previo"></v-radio>
                  <v-radio class="my-0 ms-3" hide-details="" :value="false" label="No hay bloqueo"></v-radio>
                </v-radio-group>
                <v-divider class="my-2"></v-divider>
                <h2 class="primary--text">OBSERVACIONES Y DETALLES EN GENERAL</h2>
                <v-textarea filled dense label="" hide-details="" v-model="pb.decoracionBodaALaCarta"></v-textarea>
              </v-col>
            </v-row>
          </template>
        </template>
      </v-card-text>
      <v-card-actions :class="$vuetify.theme.dark ? 'sticky-actions-dark' : 'sticky-actions-light'">
        <v-btn color="error" @click="cancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-btn color="primary" @click="agree">
          <v-icon>mdi-floppy</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <div class="d-flex justify-center align-center">
          <span class="error--text me-5">Campos obligatorios marcados con *</span>
          <v-btn small @click="prev" v-if="page > 1">Volver</v-btn>
          <v-btn color="primary" small @click="next" v-if="page < maxPages">Siguiente</v-btn>
          <v-btn color="success" small @click="send" v-if="page == maxPages"><v-icon>Finalizar</v-icon></v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { req } from "@/utils/validations.js";
export default {
  data: () => ({
    loading: true,
    dialog: false,
    datos: {},
    resolve: null,
    rules: { req },
    valid: true,
    reject: null,
    page: 1,
    options: {
      width: 1300,
      zIndex: 200
    },
    pb:{}
  }),
  computed:{
    maxPages(){
      return 6
    }
  },
  methods: {
    prev(){
      this.page--
    },
    next(){
      if (this.$refs['form'+this.page]) {
        if (!this.$refs['form'+this.page].validate()) {
          this.$root.$emit("snack", "Revisa los campos obligatorios marcados con *")
          return
        }
      }
      this.page++
    },
    send(){

    },
    open(pb) {
      this.loading = true
      this.dialog = true
      this.valid = true
      this.datos = Object.assign(this.datos, pb)
      this.pb = Object.assign(this.pb, pb.tapas)
      this.loading = false
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree() {
      this.resolve(this.pb)
      this.dialog = false
      this.page = 1
    },
    cancel() {
      this.resolve(false)
      this.dialog = false
      this.page = 1
    },
  },
  mounted(){
  }
}
</script>

<style>
.noArrow input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}
.noArrow input::-webkit-outer-spin-button,
.noArrow input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.sticky-actions-dark {
  position: sticky;
  bottom: 0;
  background: var(--v-secondary-base);
  z-index: 201;
}
.sticky-actions-light {
  position: sticky;
  bottom: 0;
  background: rgb(244,244,244);
  z-index: 201;
}
</style>